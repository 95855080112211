import React from 'react'
import Layout from '../../components/Layout'
import HeaderMeta from '../../components/HeaderMeta';

import {
  ResponsiveMaxWidthFrame,
  SectionGroup,
  PageNameBox,
  TitleBox,
} from "@minware/ui-minware";

const header = {
  title: `Thank you for contacting minware`,
  description: `Thank you for contacting minware, we will reply to you shortly.`,
  canonicalUrl: '/contact/thanks',
}

export default () => (
  <Layout desktopBgImageName="none">
    <HeaderMeta header={header}/>
    <ResponsiveMaxWidthFrame maxWidth="500px">
        <SectionGroup>
          <PageNameBox>Contact Confirmation</PageNameBox>
          <TitleBox>
            Thanks! We will contact you shortly.
          </TitleBox>
        </SectionGroup>
    </ResponsiveMaxWidthFrame>
  </Layout>
)
